<template>
  <div class="campaign-details">

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>

    <div v-else>
      <div class="d-flex">
        <v-img
          :src="campaign.brand_image"
          lazy-src="@/assets/images/placeholder.png"
          :aspect-ratio="16/16"
          width="180"
        />
        <v-card
          class="ml-4"
          style="width: 100%"
        >
          <v-card-text>
            <h5 class="mb-6">{{ campaign.campaign_name }}</h5>

            <div class="d-flex align-center justify-space-between text-center mb-4">
              <p>
                <span class="label">Brand</span> <br>
                <strong>{{ campaign.company_name || '-' }}</strong>
              </p>
              <p>
                <span class="label">Platform</span> <br>
                <strong>{{ campaign.promo_platform | platform }}</strong>
              </p>
              <p>
                <span class="label">Campaign Type</span> <br>
                <strong>{{ campaign.campaign_type | campaignType }}</strong>
              </p>
              <p>
                <span class="label">Content Type</span> <br>
                <strong v-if="campaign.promo_platform === 1">{{campaign.content_type | youtube }}</strong>
                <strong v-else>{{ campaign.content_type | instagram }}</strong>
              </p>
              <p>
                <span class="label">Budget</span> <br>
                <strong v-if="campaign.budget">{{campaign.budget | formatNumber}}</strong>
                <strong v-else>-</strong>
              </p>
            </div>
            <p>
              <span class="label">Application Timeline</span> <br>
              <strong>{{ campaign.start_date | dateFormat }} ~ {{ campaign.end_date | dateFormat }}</strong>
            </p>
          </v-card-text>
        </v-card>
      </div>

      <v-card class="elevation-0 grey-bg mt-6 mb-6">
        <v-card-text>
          <h5 class="mb-6">Eligibility Criteria</h5>

          <div class="d-flex align-center justify-space-between text-center">
            <p>
              <span class="label">Category</span> <br>
              <strong>
                <span
                  v-for="category in campaign.category"
                  :key="category"
                >{{ category }}, </span>
              </strong>
            </p>
            <p>
              <span class="label">Language</span> <br>
              <strong>
                <span
                  v-for="language in campaign.language"
                  :key="language"
                >{{ language }}, </span>
              </strong>
            </p>
            <p>
              <span class="label">Gender</span> <br>
              <strong>
                <span
                  v-for="gender in campaign.gender"
                  :key="gender"
                >{{ gender }}, </span>
              </strong>
            </p>
            <p>
              <span class="label">{{campaign.promo_platform === 1 ? 'Minimum Subscribers' : 'Minimum Followers'}}</span> <br>
              <strong>{{ campaign.min_follower| formatNumber }} ~ {{ campaign.max_follower | formatNumber }}</strong>
            </p>
          </div>

        </v-card-text>
      </v-card>

      <v-card class="elevation-0 grey-bg mb-6">
        <v-card-text>
          <h5 class="mb-4">Description of Campaign</h5>

          <p><strong>{{ campaign.campaign_desc || '-'}}</strong></p>

        </v-card-text>
      </v-card>
      <v-card class="elevation-0 grey-bg mb-6">
        <v-card-text>
          <h5 class="mb-4">Deliverable</h5>

          <p><strong>{{ campaign.deliverable || '-' }}</strong></p>

        </v-card-text>
      </v-card>
      <div class="text-right mb-6">
        <v-btn
          depressed
          dark
          large
          color="primary"
          style="width: 330px;"
          @click="applyremove()"
        >{{status === 4 ? 'Apply Now' : 'Campaign Applied'}}</v-btn>
      </div>
      <v-card
        class="elevation-0 grey-bg mb-6"
        v-if="campaign.channelResult && campaign.channelResult.length"
      >
        <v-card-text>
          <div class="d-flex align-center mb-4">
            <v-icon
              v-if="campaign.promo_platform === 1"
              class="mr-2"
              color="#ff0101"
              x-large
            >mdi-youtube</v-icon>
            <img
              v-else
              class="mr-2 mt-2 mb-2"
              src="@/assets/images/icon-instagram.svg"
              width="30"
            />
            <h5>Applied Channels</h5>
          </div>
          <div v-if="pendingchannelIds.length">
            <v-chip
              color="warning"
              class="mb-4"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-alert-circle-outline</v-icon> Pending
            </v-chip>
            <v-row class="d-flex align-center mb-4">
              <v-col
                v-for="item in pendingchannelIds"
                :key="item.id"
                cols="12"
                md="4"
                xm="12"
                style="padding:8px;"
              >
                <div class="channelCard">
                  <p>
                    <strong>{{ item.name }}</strong>
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="success"
                          v-if="item.verify"
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="ml-1"
                        >mdi-check-decagram</v-icon>
                        <v-icon
                          color="warning"
                          v-bind="attrs"
                          v-on="on"
                          small
                          v-else
                          class="ml-1"
                        >mdi-alert-circle</v-icon>
                      </template>
                      <div class="d-flex justify-center">
                        <span v-if="item.verify">Authorized Channel</span>
                        <span v-else>{{item.media_type === 0 ? 'This channel is not authorized. please go to profile page and connect your YouTube account' : 'This channel is not authorized. please go to profile page and connect your Instagram page'}}</span>
                      </div>
                    </v-tooltip>
                    <br />
                    <a
                      :href="`${item.media_type === 0 ? 'https://www.youtube.com/channel/'+ item.channel_id:'https://www.instagram.com/'+item.insta_username}`"
                      target="_blank"
                      class="linkChannel"
                    >
                      <small>{{ item.media_type === 0 ? item.channel_id : item.insta_username }}</small>
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-if="shortListchannelIds.length">
            <v-chip
              color="pink"
              class="mb-4"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-heart</v-icon> Shortlisted
            </v-chip>
            <v-row class="d-flex align-center mb-4">
              <v-col
                v-for="item in shortListchannelIds"
                :key="item.id"
                cols="12"
                md="4"
                xm="12"
                style="padding:8px;"
              >
                <div class="channelCard">
                  <p>
                    <strong>{{ item.name }}</strong>
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="success"
                          v-if="item.verify"
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="ml-1"
                        >mdi-check-decagram</v-icon>
                        <v-icon
                          color="warning"
                          v-bind="attrs"
                          v-on="on"
                          small
                          v-else
                          class="ml-1"
                        >mdi-alert-circle</v-icon>
                      </template>
                      <div class="d-flex justify-center">
                        <span v-if="item.verify">Authorized Channel</span>
                        <span v-else>{{item.media_type === 0 ? 'This channel is not authorized. please go to profile page and connect your YouTube account' : 'This channel is not authorized. please go to profile page and connect your Instagram page'}}</span>
                      </div>
                    </v-tooltip>
                    <br />
                    <a
                      :href="`${item.media_type === 0 ? 'https://www.youtube.com/channel/'+ item.channel_id:'https://www.instagram.com/'+item.insta_username}`"
                      target="_blank"
                      class="linkChannel"
                    >
                      <small>{{ item.media_type === 0 ? item.channel_id : item.insta_username }}</small>
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-if="approvechannelIds.length">
            <v-chip
              color="success"
              class="mb-4"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-check-circle-outline</v-icon> Approved
            </v-chip>
            <v-row class="d-flex align-center mb-4">
              <v-col
                v-for="item in approvechannelIds"
                :key="item.id"
                cols="12"
                md="4"
                xm="12"
                style="padding:8px;"
              >
                <div class="channelCard">
                  <p>
                    <strong>{{ item.name }}</strong>
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="success"
                          v-if="item.verify"
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="ml-1"
                        >mdi-check-decagram</v-icon>
                        <v-icon
                          color="warning"
                          v-bind="attrs"
                          v-on="on"
                          small
                          v-else
                          class="ml-1"
                        >mdi-alert-circle</v-icon>
                      </template>
                      <div class="d-flex justify-center">
                        <span v-if="item.verify">Authorized Channel</span>
                        <span v-else>{{item.media_type === 0 ? 'This channel is not authorized. please go to profile page and connect your YouTube account' : 'This channel is not authorized. please go to profile page and connect your Instagram page'}}</span>
                      </div>
                    </v-tooltip>
                    <br />
                    <a
                      :href="`${item.media_type === 0 ? 'https://www.youtube.com/channel/'+ item.channel_id:'https://www.instagram.com/'+item.insta_username}`"
                      target="_blank"
                      class="linkChannel"
                    >
                      <small>{{ item.media_type === 0 ? item.channel_id : item.insta_username }}</small>
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-if="rejectchannelIds.length">
            <v-chip
              color="error"
              small
              outlined
              class="mb-4"
            >
              <v-icon
                small
                left
              >mdi-close-octagon-outline</v-icon> Rejected
            </v-chip>
            <v-row class="d-flex align-center mb-0">
              <v-col
                v-for="item in rejectchannelIds"
                :key="item.id"
                cols="12"
                md="4"
                xm="12"
                style="padding:8px;"
              >
                <div class="channelCard">
                  <p>
                    <strong>{{ item.name }}</strong>
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="success"
                          v-if="item.verify"
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="ml-1"
                        >mdi-check-decagram</v-icon>
                        <v-icon
                          color="warning"
                          v-bind="attrs"
                          v-on="on"
                          small
                          v-else
                          class="ml-1"
                        >mdi-alert-circle</v-icon>
                      </template>
                      <div class="d-flex justify-center">
                        <span v-if="item.verify">Authorized Channel</span>
                        <span v-else>{{item.media_type === 0 ? 'This channel is not authorized. please go to profile page and connect your YouTube account' : 'This channel is not authorized. please go to profile page and connect your Instagram page'}}</span>
                      </div>
                    </v-tooltip>
                    <br />
                    <a
                      :href="`${item.media_type === 0 ? 'https://www.youtube.com/channel/'+ item.channel_id:'https://www.instagram.com/'+item.insta_username}`"
                      target="_blank"
                      class="linkChannel"
                    >
                      <small>{{ item.media_type === 0 ? item.channel_id : item.insta_username }}</small>
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <campaignPost
        v-if="campaign.campaign_name"
        :approveChannel="approvechannelIds"
        :campaignData="campaign"
      />
      <v-dialog
        v-model="channelDialogue"
        width="50%"
        persistent
      >
        <v-card class="pa-4">
          <div v-if="channelIds.length > 0">
            <p class="mb-4">
              <strong>Please select the channel which you want to apply</strong>
            </p>
            <v-progress-linear
              v-if="loadingDialogue"
              class="mb-8"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-row
              v-else
              class="d-flex align-center"
            >
              <!-- class="align-center" -->
              <!-- justify="center" -->
              <!-- justify="align" -->
              <!-- class="align-center" -->
              <!-- class="d-flex justify-align" -->
              <!-- class="justify-center" -->
              <!-- class="d flex-align-center" -->
              <!-- {{mediaId}} -->
              <v-col
                v-for="item in channelIds"
                :key="item.id"
                cols="12"
                md="6"
                xm="12"
                style="padding:6px;"
              >
                <div class="d-inline-flex align-center channelCard">
                  <v-checkbox
                    v-model="mediaId"
                    :value="{media_id: item.id, status: item.status}"
                    multiple
                    hide-details
                    :indeterminate="item.status===2?true:false"
                    :disabled="item.status===1 ||item.status===2"
                    class="ma-0 mr-3"
                  ></v-checkbox>
                  <div class="d-flex align-center">
                    <img
                      v-if="item.media_type === 1"
                      class="mr-2"
                      src="@/assets/images/icon-instagram.svg"
                      width="30"
                    />
                    <v-icon
                      v-else
                      class="mr-2"
                      color="#ff0101"
                      x-large
                    >mdi-youtube</v-icon>
                    <p>
                      <strong>{{ item.name }}</strong>
                      <v-tooltip
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="success"
                            v-if="item.verify"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="ml-1"
                          >mdi-check-decagram</v-icon>
                          <v-icon
                            color="warning"
                            v-bind="attrs"
                            v-on="on"
                            small
                            v-else
                            class="ml-1"
                          >mdi-alert-circle</v-icon>
                        </template>
                        <v-chip
                          v-if="item.status !== null"
                          small
                          color="white"
                          outlined
                        >
                          <v-icon
                            small
                            v-if="item.status===0"
                            left
                          >mdi-alert-circle-outline</v-icon>
                          <v-icon
                            small
                            v-if="item.status===1"
                            left
                          >mdi-check-circle-outline</v-icon>
                          <v-icon
                            small
                            v-if="item.status===2"
                            left
                          >mdi-close-octagon-outline</v-icon>{{item.status | influencerStatus}}
                        </v-chip>
                        <div class="d-flex justify-center">
                          <span v-if="item.verify">Authorized Channel</span>
                          <span v-else>{{item.media_type === 0 ? 'This channel is not authorized. please go to profile page and connect your YouTube account' : 'This channel is not authorized. please go to profile page and connect your Instagram page'}}</span>
                        </div>
                      </v-tooltip>
                      <br />
                      <a
                        :href="`${item.media_type === 0 ? 'https://www.youtube.com/channel/'+ item.channel_id:'https://www.instagram.com/'+item.insta_username}`"
                        target="_blank"
                        class="linkChannel"
                      >
                        <small>{{ item.media_type === 0 ? item.channel_id : item.insta_username }}</small>
                      </a>
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>

            <div class="d-flex justify-end mt-6">
              <v-btn
                color="secondary"
                class="mr-4"
                outlined
                small
                :disabled="loadingbtn"
                @click="channelDialogue=false"
              >
                Close
              </v-btn>

              <v-btn
                color="primary"
                outlined
                small
                :disabled="loadingDialogue"
                @click="submitdialog()"
                :loading="loadingbtn"
              >
                Submit
              </v-btn>
            </div>
          </div>

          <div v-else>
            <p class="text-center mt-4">
              <strong class="text-uppercase">You must link your social media accounts in order to apply for a campaign.</strong>
              <br> <br>
              Please go to your profile and connect your YouTube channel or enter the channel url under the <em>"Channel Links"</em> section.
              <br />
              <v-btn
                class="mt-4"
                color="primary"
                to="/profile/info"
                small
              >Go to profile</v-btn>
            </p>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { getInfo, applyCampaign } from '@/api/campaign'
import campaignPost from './detailComponent/post.vue'
export default {
  components: { campaignPost },
  data () {
    return {
      loggedIn: window.localStorage.getItem('user-token'),
      loading: false,
      channelDialogue: false,
      postDialogue: false,
      loadingDialogue: false,
      status: null,
      loadingbtn: false,
      campaign: {},
      campData: {},
      channelIds: [],
      pendingchannelIds: [],
      approvechannelIds: [],
      rejectchannelIds: [],
      shortListchannelIds: [],
      mediaId: [],
      userData: []
    }
  },
  mounted () {
    this.userData = JSON.parse(window.localStorage.getItem('user'))
    if (this.$route.params.campaignId) {
      // this.status = parseInt(this.$route.params.status)
      this.getCampaignDetails()
    }
  },
  methods: {
    async applyremove () {
      if (!this.loggedIn) {
        this.$router.push(`/user/login?redirect=${this.$route.path}`)
        return
      }
      this.channelDialogue = true
      this.loadingDialogue = true

      const filteredStatus = this.channelIds.filter(x => x.status != null)

      this.mediaId = filteredStatus.map(x => {
        if (x.status != null) {
          return {
            media_id: x.id, status: x.status
          }
        }
      })
      this.loadingDialogue = false
    },
    async submitdialog () {
      if (!this.loggedIn) {
        this.$router.push(`/user/login?redirect=${this.$route.path}`)
        return
      }
      this.loadingbtn = true
      const campInfluData = {
        campaignId: this.$route.params.campaignId,
        mediaType: this.campaign.promo_platform,
        mediaId: this.mediaId,
        client_id: this.campaign.client_id
      }
      const data = await applyCampaign(campInfluData)
      this.loadingbtn = false
      if (data.success) {
        this.$snackbar({
          message: data.message
        })
        this.channelDialogue = false
        await this.getCampaignDetails()
        // if (this.mediaId.length) {
        //   this.status = 0
        // } else {
        //   this.status = 3
        // }
      } else {
        this.$snackbar({
          message: data.message,
          color: 'error',
          timeout: 5000
        })
      }
    },
    async getCampaignDetails () {
      this.loading = true
      const data = await getInfo(this.$route.params.campaignId)
      this.loading = false
      this.campaign = data.campaign
      this.channelIds = this.campaign.promo_platform === 1 ? this.userData.ytChannel : this.userData.instaChannel
      for (let i = 0; i < this.channelIds.length; i++) {
        // use to match id from one arr obj to another arr obj and insert data into matched object.
        const index = data.campaign.channelResult.findIndex(object =>
          object.media_id === this.channelIds[i].id
        )
        if (index !== -1) {
          this.channelIds[i].status = data.campaign.channelResult[index].status
        } else {
          this.channelIds[i].status = null
        }
      }
      this.pendingchannelIds = this.channelIds.filter(row => row.status === 0)
      this.rejectchannelIds = this.channelIds.filter(row => row.status === 2)
      this.shortListchannelIds = this.channelIds.filter(row => row.status === 3)
      this.approvechannelIds = this.channelIds.filter(row => row.status === 1)
      this.approvechannelIds = this.approvechannelIds.map(x => {
        x.channeldata = {
          media_id: x.id,
          channel_id: x.channel_id || x.insta_username
        }
        return x
      })
      this.campaign.category = this.campaign.category ? this.campaign.category.split(',') : []
      this.campaign.language = this.campaign.language ? this.campaign.language.split(',') : []
      this.campaign.gender = this.campaign.gender ? this.campaign.gender.split(',') : []
    }
  }
}
</script>

<style scoped>
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #6b6b6b;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  strong {
    font-weight: 600;
  }

  .grey-bg {
    background: rgba(217, 217, 217, 0.17);
  }

  /* table css */
  .v-data-table >>> .v-data-table__wrapper > table {
    border-spacing: 0 12px !important;
  }
  .theme--light.v-data-table >>> .v-data-table__wrapper table th {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
  .v-data-table >>> tbody tr.v-data-table__selected {
    background: #e9ecfa;
  }
  .v-image {
    border-radius: 11px;
    box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.11);
    margin: 0 auto;
  }
  .v-image >>> .v-image__image--preload {
    filter: none;
  }
  .v-input >>> .v-input--selection-controls__input {
    margin: 0 !important;
  }
</style>
